<template>
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('subMenu.status') }}</h3>
        <div class="sort-side">
          <select class="isf term-sel" v-model="searchCondition">
            <option value="">{{ $t('status.search-condition1') }}</option>
            <option value="platform">{{
              $t('status.search-condition2')
            }}</option>
            <option value="analEnv">{{
              $t('status.search-condition3')
            }}</option>
          </select>
          <span class="search-icon-wrap">
            <input
              type="text"
              class="isf"
              :placeholder="srvcIdPlaceholder"
              v-model="searchData"
              maxlength="20"
              dataType="LITERAL"
              v-on:keyup.enter="onSearch"
            />
            <button class="ic-search" @click="onSearch">
              {{ $t('common.btn-search') }}
            </button>
          </span>
        </div>
      </div>
      <list-form
        :fetchApi="fetchStatusList"
        :columns="columns"
        ref="statusList"
      />
    </div>
    <!--/contents-->
  </div>
  <!--/container-->
</template>
<script>
import ListForm from '@/components/common/ListForm';
import CommonMixins from '@/mixins/CommonMixins.js';
import { fetchStatusList } from '@/api/requestApi.js';

export default {
  components: { ListForm },

  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.solutions'),
        this.$i18n.t('subMenu.status'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.solutions'),
        smallTitle: this.$i18n.t('menu.solutions-eng'),
      },
      srvcIdPlaceholder: this.$i18n.t('common.placeholder.field-srvcId'),
      columns: [
        {
          label: this.$i18n.t('status.table-column1'),
          field: 'title',
          sortable: false,
          width: '66%',
        },
        {
          label: this.$i18n.t('status.table-column2'),
          field: 'srvcId',
          sortable: false,
          width: '10%',
        },
        {
          label: this.$i18n.t('status.table-column3'),
          field: 'reqDt',
          sortable: false,
          formatFn: val => CommonMixins.methods.common_getDateString2(val),
          width: '10%',
        },
        {
          label: this.$i18n.t('status.table-column4'),
          field: 'status',
          sortable: false,
          formatFn: val => this.setLocale(val),
          width: '10%',
        },
      ],
      searchCondition: '',
      searchData: '',
    };
  },
  methods: {
    // 이용신청 현황 목록
    fetchStatusList(params) {
      return fetchStatusList(params);
    },
    // 검색
    onSearch() {
      let params = {
        page: 1,
        srchCat: this.searchCondition,
        srvcId: this.searchData,
      };
      this.$refs.statusList.onSearch(params);
    },
    setLocale(val) {
      switch (val) {
        case '승인요청':
          return this.$i18n.t('status.status-req');
        case '승인완료':
          return this.$i18n.t('status.status-fin');
        case '접속불가':
          return this.$i18n.t('status.status-disconnect');
        case '승인거절':
          return this.$i18n.t('status.status-deny');
        default:
          return this.$i18n.t('status.status-deny');
      }
    },
  },
};
</script>
<style></style>
